import React from "react";
import "./PageLoader.css";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";

function PageLoader() {
  return (
    <div className="pageLoaderContainer">
      <SlSpinner style={{ fontSize: "2rem" }} />
    </div>
  );
}

export default PageLoader;
