import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { toastError, ToastMessage } from "../../../../utils/toast";
import { AlumnosInitState } from "../../Slices/alumnosSlice";

export const inicializarAlumno = createAsyncThunk(
  "dashboard/inicializarAlumno",
  async (email: string, thunkAPI) => {
    try {
      console.log("inicializarAlumno...");
      const response = await dashboardAPI.inicializarAlumno(email);
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const inicializarAlumnoBuilder = (
  builder: ActionReducerMapBuilder<AlumnosInitState>
) => {
  builder.addCase(inicializarAlumno.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(inicializarAlumno.fulfilled, (state, action) => {
    console.log("inicializarAlumno fulfilled :>> ", action);
    state.loading = false;
  });
  builder.addCase(inicializarAlumno.rejected, (state, action) => {
    console.log("inicializarAlumno rejected :>> ", action);
    toastError(ToastMessage.error);
    state.loading = false;
  });
};
