import React, { useEffect } from "react";
import Selector from "./reusable/Selector/Selector";
import InputSearch from "./reusable/InputSearch/InputSearch";
import { useSelector } from "react-redux";
import {
  selectAlumnosCarreras,
  selectBuscadorAlumnosRegistrados,
  setCarreraSearch,
  setRegistradosCurrentPage,
  setTextSearch,
} from "../features/Dashboard/Slices/alumnosSlice";
import { useAppDispatch } from "../app/store";
import { getAlumnosRegistrados } from "../features/Dashboard/AsyncThunks/alumnos/getAlumnosRegistrados";
import { getCarreras } from "../features/Dashboard/AsyncThunks/alumnos/getCarreras";
import Button from "./reusable/Button/Button";

function BuscadorAlumnosRegistrados() {
  const carrerasList = useSelector(selectAlumnosCarreras);
  const buscador = useSelector(selectBuscadorAlumnosRegistrados);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getCarrerasList();
  }, []);

  const getCarrerasList = async () => {
    await dispatch(getCarreras());
  };

  const buscarAlumnos = async () => {
    await dispatch(setRegistradosCurrentPage(1));
    await dispatch(getAlumnosRegistrados());
  };

  const setSearchAlumnosFilter = async (text: string) => {
    await dispatch(setTextSearch(text));
  };

  const saveCarrera = async (text: string) => {
    await dispatch(setCarreraSearch(text));
  };

  return (
    <div className="flex flex-row mt-4 items-end self-start">
      <Selector
        options={carrerasList.map((carrera) => {
          return { text: carrera, value: carrera };
        })}
        onSelect={saveCarrera}
        placeholder="Seleccionar una carrera"
        className={"mr-3 text-sm"}
        label="Filtro por carrera"
      />
      <div>
        <p>Buscar por emails, idAlumnoPrograma, idPersona, estado y nombre de alumno</p>
        <InputSearch
          onChange={setSearchAlumnosFilter}
          placeholder="Buscar alumnos"
          onConfirmSearch={buscarAlumnos}
          value={buscador.filtroText}
        />
      </div>
      <Button className="ml-3 w-32" onClick={buscarAlumnos} label="Buscar" />
    </div>
  );
}

export default BuscadorAlumnosRegistrados;
