import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { DashboardInitState } from "../../Slices/dashboardSlice";

const services = [
  {
    name: "Backend Notifications",
    getVersion: async () => await dashboardAPI.getVersionBackNotification(),
  },
  {
    name: "Backend Proxy",
    getVersion: async () => await dashboardAPI.getVersionBackProxy(),
  },
  {
    name: "Backend Sync",
    getVersion: async () => await dashboardAPI.getVersionBackSync(null),
  },
  {
    name: "Backend API",
    getVersion: async () => await dashboardAPI.getVersionBackApi(null),
  },
  {
    name: "Backend API #1",
    getVersion: async () => await dashboardAPI.getVersionBackApi(1),
  },
  {
    name: "Backend API #2",
    getVersion: async () => await dashboardAPI.getVersionBackApi(2),
  },
  {
    name: "Backend API #3",
    getVersion: async () => await dashboardAPI.getVersionBackApi(3),
  },
  {
    name: "Backend API #4",
    getVersion: async () => await dashboardAPI.getVersionBackApi(4),
  },
  {
    name: "Backend API #5",
    getVersion: async () => await dashboardAPI.getVersionBackApi(5),
  },
  {
    name: "Backend API #6",
    getVersion: async () => await dashboardAPI.getVersionBackApi(6),
  },
  {
    name: "Backend API #7",
    getVersion: async () => await dashboardAPI.getVersionBackApi(7),
  },
  {
    name: "Backend API #8",
    getVersion: async () => await dashboardAPI.getVersionBackApi(8),
  },
  {
    name: "Backend Sync #1",
    getVersion: async () => await dashboardAPI.getVersionBackSync(1),
  },
  {
    name: "Backend Sync #2",
    getVersion: async () => await dashboardAPI.getVersionBackSync(2),
  },
  {
    name: "Backend Sync #3",
    getVersion: async () => await dashboardAPI.getVersionBackSync(3),
  },
  {
    name: "Backend Sync #4",
    getVersion: async () => await dashboardAPI.getVersionBackSync(4),
  },
  {
    name: "Backend Sync #5",
    getVersion: async () => await dashboardAPI.getVersionBackSync(5),
  },
  {
    name: "Backend Sync #6",
    getVersion: async () => await dashboardAPI.getVersionBackSync(6),
  },
  {
    name: "Backend Sync #7",
    getVersion: async () => await dashboardAPI.getVersionBackSync(7),
  },
  {
    name: "Backend Sync #8",
    getVersion: async () => await dashboardAPI.getVersionBackSync(8),
  }
];

export const getServicesVersions = createAsyncThunk(
  "dashboard/getServicesVersions",
  async (_, thunkAPI) => {
    try {
      console.log("getServicesVersions...");
      const listVersions: any[] = [];
      for (const service of services) {
        const { getVersion, name } = service;
        let versionData = {};
        try {
          const res = await getVersion();
          console.log(`${name} res :>> `, res);
          versionData = {
            name,
            version: res.version,
            environment: res.environment,
          };
        } catch (error) {
          versionData = { name, version: "-", environment: "-" };
        }
        listVersions.push(versionData);
      }
      return listVersions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getServicesVersionsBuilder = (
  builder: ActionReducerMapBuilder<DashboardInitState>
) => {
  builder.addCase(getServicesVersions.fulfilled, (state, action) => {
    console.log("getServicesVersions fulfilled :>> ", action);
    state.servicesVersions = action.payload || [];
  });
};
