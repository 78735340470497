export enum Parametros {
  EstadoSistema = "ESTADO_SISTEMA",
}

export enum EstadoSistema {
  ABIERTO = "0",
  CERRADO = "1",
  BY_PASS = "2",
  MANTENIMIENTO = "3",
}
