import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/store";
import { selectParametros } from "../../features/Dashboard/Slices/dashboardSlice";
import { useSelector } from "react-redux";
import Input from "../../components/reusable/Input/Input";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { ToastMessage, toastError, toastSuccess } from "../../utils/toast";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import { getParametros } from "../../features/Dashboard/AsyncThunks/parametros/getParametros";
import Divider from "../../components/reusable/Divider/Divider";

export enum Parametro {
  PROCESAR_NOVEDADES_ORACLE_MANUALMENTE = "PROCESAR_NOVEDADES_ORACLE_MANUALMENTE",
  DESCARTAR_NOVEDADES_ORACLE = "DESCARTAR_NOVEDADES_ORACLE",
}

function Parametros() {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const parametros = useSelector(selectParametros);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [paramToEdit, setParamToEdit] = useState("");
  const [newParamValue, setNewParamValue] = useState("");

  const updateParametro = async () => {
    setLoading(true);
    try {
      await dashboardAPI.updateParametro(paramToEdit, newParamValue);
      toastSuccess(`Parámetro ${paramToEdit} actualizado correctamente`);
      setParamToEdit("");
      obtenerParametros(); // Refresh parametros
    } catch (error) {
      toastError(ToastMessage.error);
    } finally {
      setShowModalConfirmation(false);
    }
    setLoading(false);
  };

  const obtenerParametros = async () => {
    await dispatch(getParametros());
  };

  useEffect(() => {
    obtenerParametros();
    console.log("Obtener parametros...");
  }, []);

  return (
    <div className="flex flex-1">
      {loading && <PageLoader />}
      <ModalConfirmation
        show={showModalConfirmation}
        message={`Seguro que quiere actualizar el parámetro ${paramToEdit}?`}
        onClose={() => setShowModalConfirmation(false)}
        onConfirm={updateParametro}
        title="Actualizar parámetro"
      />
      <div className="px-8 py-5">
        <div className="flex flex-row items-center my-3">
          <strong className="pl-2">Parámetros</strong>
          <Button
            className="ml-4"
            label="Refresh"
            iconName="arrow-clockwise"
            onClick={obtenerParametros}
          />
        </div>
        <div className="bg-white px-3 py-3 rounded-lg">
          {!loading &&
            parametros.map(({ clave, valor, descripcion }) => {
              const editMode = paramToEdit === clave;
              return (
                <div className="flex flex-col">
                  <div key={clave} className="flex flex-row items-center my-2">
                    <div className="w-72">
                      <p>{clave}</p>
                    </div>

                    <Input
                      defaultValue={valor}
                      onChange={setNewParamValue}
                      disabled={!editMode}
                      className="pl-2"
                    />

                    <Button
                      iconName={editMode ? "check-circle" : "pencil-square"}
                      className="mx-3"
                      theme={editMode ? "primary" : "blue"}
                      label={editMode ? "Actualizar" : "Editar"}
                      onClick={() => {
                        if (editMode) {
                          setShowModalConfirmation(true);
                        } else {
                          if (newParamValue.length) {
                            getParametros();
                          }
                          setNewParamValue("");
                          setParamToEdit(clave);
                        }
                      }}
                    />

                    <div className="w-96">
                      <p>{descripcion}</p>
                    </div>
                  </div>
                  <Divider className="h-1 bg-black w-full" />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Parametros;
