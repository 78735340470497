import axios from "axios";

export const login = async (email: string) => {
  try {
    const { data, status } = await axios.post("/login", { email: email });
    return data;
  } catch (error) {
    return { error };
  }
};

export const auth = async () => {
  try {
    const { data, status } = await axios.get("/auth");
    return data
  } catch (error) {
    return { error };
  }
};

export const dashLogin = async (email: string) => {
  try {
    const { data, status } = await axios.post("/doLoginDashboard", { email: email });
    return data;
  } catch (error) {
    return { error };
  }
};