import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface DashboardInitState {
  authenticated: boolean;
  token: string | null;
  rol: string | null;
  acciones: string[] | null;
  email: string | null;
}
const initState: DashboardInitState = {
  authenticated: false,
  token: null,
  rol: null,
  acciones: [],
  email: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    setAuthtenticated: (state, { payload }) => {
      state.authenticated = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setUser: (state, { payload }) => {
      state.rol = payload.rol;
      state.acciones = payload.acciones;
      state.email = payload.usuario;
    },
  },
});

export const selectAuthenticated = (state: RootState) =>
  state.user.authenticated;
export const selectUser = (state: RootState) => state.user;
export const selectToken = (state: RootState) => state.user.token;

export const { setAuthtenticated, setToken, setUser } = userSlice.actions;

export default userSlice.reducer;
