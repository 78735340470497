import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { DashboardInitState } from "../../Slices/dashboardSlice";
import { toastError, ToastMessage } from "../../../../utils/toast";

export const getDetalleCuadratura = createAsyncThunk(
  "dashboard/getDetalleCuadratura",
  async (_, thunkAPI) => {
    try {
      console.log("getDetalleCuadratura...");
      const response = await dashboardAPI.getDetalleCuadratura();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const procesarCuadratura = createAsyncThunk(
  "dashboard/calcularCuadratura",
  async (_, thunkAPI) => {
    try {
      console.log("calcularCuadratura...");
      const response = await dashboardAPI.procesarCuadratura();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getDetalleCuadraturaDeCupoBuilder = (
  builder: ActionReducerMapBuilder<DashboardInitState>
) => {
  builder.addCase(getDetalleCuadratura.pending, (state, action) => {
    state.loadingPageScreen = true;
  });
  builder.addCase(getDetalleCuadratura.fulfilled, (state, action) => {
    console.log("getDetalleCuadratura fulfilled :>> ", action);
    state.loadingPageScreen = false;
    state.detalleCuadraturaCupos = action.payload;
  });
  builder.addCase(getDetalleCuadratura.rejected, (state, action) => {
    console.log("getDetalleCuadratura rejected :>> ", action);
    toastError(ToastMessage.error);
    state.loadingPageScreen = false;
  });
};
