import React, { useEffect, useState } from "react";
import "./TableListInverted.css";

interface Props {
  list: any[];
}

function TableListInverted({ list = [] }: Props) {

  if (!list?.length) {
    return <></>;
  }
  const titles = Object.keys(list[0]);
  return (
    <div className="tableInvertedContainer">
      <div className="flex flex-row">
        <div className="w-auto">
          {titles.map((columnTitle) => (
            <div className="titleBox">
              <b>{columnTitle}</b>
            </div>
          ))}
        </div>
        <div className="flex flex-col">
          {titles.map((columnTitle) => (
            <div>
              {/* Render column records */}
              {list?.map((object) => {
                return (
                  <div className="item">
                    <p>{object[columnTitle]}</p>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TableListInverted;
