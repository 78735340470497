import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { Alumno } from "../../../types/alumnos";
import { getAlumnosInicializadosBuilder } from "../AsyncThunks/alumnos/getAlumnosInicializados";
import { getAlumnosRegistradosBuilder } from "../AsyncThunks/alumnos/getAlumnosRegistrados";
import { getDetalleDeAlumnoBuilder } from "../AsyncThunks/alumnos/getDetalleDeAlumno";
import { inicializarAlumnoBuilder } from "../AsyncThunks/alumnos/inicializarAlumno";
import { getCarrerasBuilder } from "../AsyncThunks/alumnos/getCarreras";

export interface AlumnosInitState {
  loading: boolean;
  detalleDeAlumno: any;
  alumnosRegistrados: { alumnos: Alumno[]; totalCount: number };
  alumnosInicializados: any;
  carreras: string[];
  buscadorAlumnosRegistrados: {
    carrera?: string;
    filtroText: string;
  };
  registradosCurrentPage: number;
}
const initState: AlumnosInitState = {
  loading: false,
  detalleDeAlumno: {},
  alumnosRegistrados: { alumnos: [], totalCount: 0 },
  alumnosInicializados: null,
  carreras: [],
  buscadorAlumnosRegistrados: {
    filtroText: "",
    carrera: undefined,
  },
  registradosCurrentPage: 1,
};

const alumnosSlice = createSlice({
  name: "alumnos",
  initialState: initState,
  reducers: {
    setRegistradosCurrentPage: (state, { payload }) => {
      state.registradosCurrentPage = payload;
    },
    setCarreraSearch: (state, { payload }) => {
      console.log("setCarreraSearch :>> ", payload);
      state.buscadorAlumnosRegistrados = {
        ...state.buscadorAlumnosRegistrados,
        carrera: payload,
      };
    },
    setTextSearch: (state, { payload }) => {
      state.buscadorAlumnosRegistrados = {
        ...state.buscadorAlumnosRegistrados,
        filtroText: payload,
      };
    },
  },
  extraReducers: (builder) => {
    getAlumnosInicializadosBuilder(builder);
    getAlumnosRegistradosBuilder(builder);
    getDetalleDeAlumnoBuilder(builder);
    inicializarAlumnoBuilder(builder);
    getCarrerasBuilder(builder);
  },
});

export const selectDetalleDeAlumno = (state: RootState) =>
  state.alumnos.detalleDeAlumno;

export const selectAlumnosRegistrados = (state: RootState) =>
  state.alumnos.alumnosRegistrados;
export const selectAlumnosInicializados = (state: RootState) =>
  state.alumnos.alumnosInicializados;

export const selectAlumnosCarreras = (state: RootState) =>
  state.alumnos.carreras;

export const selectBuscadorAlumnosRegistrados = (state: RootState) =>
  state.alumnos.buscadorAlumnosRegistrados;

export const selectRegistradosCurrentPage = (state: RootState) =>
  state.alumnos.registradosCurrentPage;

export const { setRegistradosCurrentPage, setCarreraSearch, setTextSearch } =
  alumnosSlice.actions;

export default alumnosSlice.reducer;
