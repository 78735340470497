import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import JsonViewer from "../../../components/reusable/JsonViewer/JsonViewer";
import TableList from "../../../components/TableList/TableList";
import { selectDetalleDeAlumno } from "../../../features/Dashboard/Slices/alumnosSlice";

function DetalleAlumno() {
  const detalleAlumno = useSelector(selectDetalleDeAlumno);
  const [reservasEnTransito, setReservasEnTransito] = useState<any[] | null>(
    null
  );
  const [desinscripcionesEnTransito, setDesinscripcionesEnTransito] = useState<
    any[] | null
  >(null);

  useEffect(() => {
    if (detalleAlumno) {
      if (detalleAlumno?.inscripcionesEnTransito) {
        setReservasEnTransito(detalleAlumno.inscripcionesEnTransito);
      }
      if (detalleAlumno?.desinscripcionesEnTransito) {
        setDesinscripcionesEnTransito(detalleAlumno.desinscripcionesEnTransito);
      }
    }
  }, [detalleAlumno]);

  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col mt-5 w-10/12">
        {reservasEnTransito && (
          <div>
            <div className="flex flex-row items-center pt-3 pb-3">
              <b className="text-left w-2/12">Reservas en transito</b>
            </div>
            <TableList list={reservasEnTransito} />
            {reservasEnTransito?.length < 1 && (
              <p>No se encontraron reservas en transito</p>
            )}
          </div>
        )}
        {desinscripcionesEnTransito && (
          <div>
            <div className="flex flex-row items-center pt-3 pb-3">
              <b className="text-left w-2/12">Desinscripciones en transito</b>
            </div>
            <TableList list={desinscripcionesEnTransito} />
            {desinscripcionesEnTransito?.length < 1 && (
              <p>No se encontraron desinscripciones en transito</p>
            )}
          </div>
        )}
        <b className="text-center">Detalle del alumno</b>
        {/* <div className="flex flex-row justify-center mt-2">
          <Input
            className="w-10/12"
            placeholder="Ingrese el IdAlumnoProgramar(Número)"
            type="number"
            onChange={(text) => setIdAlumnoPrograma(Number(text))}
          />
          <Button
            className="ml-3 w-2/12"
            onClick={ObtenerDetalleAlumno}
            label="Buscar"
          />
        </div> */}
        <div className="pt-5 w-12/12">
          <JsonViewer json={detalleAlumno} />
        </div>
      </div>
    </div>
  );
}

export default DetalleAlumno;
