import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { DashboardInitState } from "../../Slices/dashboardSlice";
import { toastError, ToastMessage } from "../../../../utils/toast";

export interface Validacion {
  id: number;
  descripcion: string;
  activa: number;
  configuracion: string;
  tipo: number;
}


export const getInicializarValidaciones = createAsyncThunk(
  "dashboard/getInicializarValidaciones",
  async (_, thunkAPI) => {
    try {
      console.log("getInicializarValidaciones...");
      const response = await dashboardAPI.inicializarValidaciones();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getValidaciones = createAsyncThunk(
  "dashboard/getValidaciones",
  async (_, thunkAPI) => {
    try {
      console.log("getValidaciones...");
      const response = await dashboardAPI.obtenerValidaciones();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getValidacionesBuilder = (
  builder: ActionReducerMapBuilder<DashboardInitState>
) => {
  builder.addCase(getValidaciones.pending, (state, action) => {
    state.loadingPageScreen = true;
  });
  builder.addCase(getValidaciones.fulfilled, (state, action) => {
    console.log("getValidaciones fulfilled :>> ", action);
    state.loadingPageScreen = false;
    state.validaciones = action.payload;
  });
  builder.addCase(getValidaciones.rejected, (state, action) => {
    console.log("getValidaciones rejected :>> ", action);
    toastError(ToastMessage.error);
    state.loadingPageScreen = false;
  });
};
