import { useEffect } from "react";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios";
import { useSelector } from "react-redux";
import { conf } from "../utils/config";
import {
  selectAuthenticated,
  selectToken,
  setAuthtenticated,
  setToken,
} from "../features/Dashboard/Slices/userSlice";
import { useAppDispatch } from "../app/store";
import { useNavigate } from "react-router-dom";

export enum ServerStatus {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  InternalServerError = 500,
}

function AxiosManager() {
  const token = useSelector(selectToken);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authenticated = useSelector(selectAuthenticated);
  const defaultConfig: AxiosRequestConfig = {
    baseURL: conf("BACKEND_API_URL"),
  };

  const handleLogout = async () => {
    localStorage.clear();
    dispatch(setToken(null));
    dispatch(setAuthtenticated(false));
    navigate("/login");
  };

  useEffect(() => {
    const tkn = localStorage.getItem("token");
    if (tkn) {
      dispatch(setToken(tkn));
      dispatch(setAuthtenticated(true));
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      axios.interceptors.response.use(
        (response) => {
          if (response.status === ServerStatus.Unauthorized) {
            handleLogout();
          }
          return response;
        },
        (error: AxiosError) => {
          if (error.response?.status === ServerStatus.Unauthorized) {
            handleLogout();
          }
          return Promise.reject(error);
        }
      );
    }
    axios.interceptors.request.use(
      (config) => {
        config.baseURL = defaultConfig.baseURL;
        const tkn = localStorage.getItem("token");
        const newToken = token ? token : tkn;
        if (newToken) {
          config.headers = {
            ...config.headers,
            Authorization: "Bearer " + newToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          } as AxiosRequestHeaders;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token, authenticated]);

  return <></>;
}

export default AxiosManager;
