import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { DashboardInitState } from "../../Slices/dashboardSlice";
import { ToastMessage, toastError } from "../../../../utils/toast";

export const getParametros = createAsyncThunk(
  "dashboard/getParametros",
  async (_, thunkAPI) => {
    try {
      console.log("getParametros...");
      const response = await dashboardAPI.obtenerParametros();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getParametrosBuilder = (
  builder: ActionReducerMapBuilder<DashboardInitState>
) => {
  builder.addCase(getParametros.pending, (state, action) => {
    state.loadingPageScreen = true;
  });
  builder.addCase(getParametros.fulfilled, (state, action) => {
    console.log("getParametros fulfilled :>> ", action);
    state.loadingPageScreen = false;
    state.parametros = action.payload;
  });
  builder.addCase(getParametros.rejected, (state, action) => {
    console.log("getParametros rejected :>> ", action);
    toastError(ToastMessage.error);
    state.loadingPageScreen = false;
  });
};
