import React, { useEffect, useState } from "react";
import "./TableList.css";
import Input from "../reusable/Input/Input";
import Button, { ButtonTheme } from "../reusable/Button/Button";

interface Props {
  list: any[];
  enableSearchbar?: boolean;
  extraColumnButtons?: {
    action: (record: any) => void;
    columnTitle: string;
    buttonLabel: string;
    iconName?: string;
    theme?: ButtonTheme;
  }[];
  customColumns?: {
    columnTitle: string;
    boxContent: (data: any, index: number) => JSX.Element;
  }[];
}

function TableList({
  list = [],
  enableSearchbar = true,
  extraColumnButtons,
  customColumns,
}: Props) {
  const [listFiltered, setListFiltered] = useState(list);
  const [filters, setFilters] = useState<
    { filterKey: string; searchValue: string }[]
  >([]);

  useEffect(() => {
    if (filters.length) {
      const newList = list.filter((record) => {
        for (const filter of filters) {
          const { filterKey, searchValue } = filter;
          const value = record[filterKey];
          const isValid =
            String(value).toUpperCase().indexOf(searchValue.toUpperCase()) > -1;
          if (!isValid) return false;
        }
        return true;
      });
      setListFiltered(newList);
    } else {
      setListFiltered(list);
    }
  }, [filters]);

  useEffect(() => {
    setListFiltered(list);
  }, [list]);

  const filterList = (searchValue: string, filterKey: string) => {
    if (searchValue.length < 1) {
      //clear filter
      const newState = filters.filter(
        (filter) => filter.filterKey !== filterKey
      );
      setFilters(newState);
    } else {
      if (filters.some((filter) => filter.filterKey === filterKey)) {
        // Update filter
        const auxFilters = filters.filter(
          (item) => item.filterKey !== filterKey
        );
        setFilters([...auxFilters, { filterKey, searchValue }]);
      } else {
        setFilters([...filters, { filterKey, searchValue }]);
      }
    }
  };

  if (!list?.length) {
    return <></>;
  }

  const renderCustomColumns = () => {
    if (!customColumns) {
      return <></>;
    } else {
      const buttons = customColumns.map((customColumn) => {
        return (
          <div>
            <div className="titleBox">
              <b className="texto-en-linea">{customColumn.columnTitle}</b>
            </div>
            <div className="filterBox border-b-2 border-grey" />
            {listFiltered?.map((object, index) => {
              return (
                <div className="item flex items-center">
                  {customColumn.boxContent(object, index)}
                </div>
              );
            })}
          </div>
        );
      });
      return buttons;
    }
  };

  const renderColumnButton = () => {
    if (!extraColumnButtons) {
      return <></>;
    } else {
      const buttons = extraColumnButtons.map((buttonColumn) => {
        return (
          <div>
            <div className="titleBox">
              <b className="texto-en-linea">{buttonColumn.columnTitle}</b>
            </div>
            {enableSearchbar && (
              <div className="filterBox border-b-2 border-grey" />
            )}
            {listFiltered?.map((object) => {
              return (
                <div className="item flex items-center">
                  <Button
                    theme={buttonColumn.theme || "primary"}
                    label={buttonColumn.buttonLabel}
                    iconName={buttonColumn.iconName}
                    className="h-5 texto-en-linea w-full"
                    onClick={() => buttonColumn.action(object)}
                  />
                </div>
              );
            })}
          </div>
        );
      });
      return buttons;
    }
  };

  const renderDataToString = (data: any) => {
    if (typeof data === "string") {
      return data;
    } else if (typeof data === "object") {
      return JSON.stringify(data);
    }
    return data;
  };

  const titles = Object.keys(list[0]);
  return (
    <div className="tableContainer">
      <div className="titleTable">
        {renderCustomColumns()}
        {renderColumnButton()}
        {titles.map((columnTitle) => (
          <div className="flex flex-col">
            {/* Render key title */}
            <div className="titleBox">
              <b>{columnTitle}</b>
            </div>
            {/* Render search filter */}
            {enableSearchbar && (
              <div className="filterBox">
                <Input
                  placeholder="filter"
                  size="small"
                  className="w-full"
                  onChange={(text) => filterList(text, columnTitle)}
                />
              </div>
            )}
            {/* Render column records */}
            {listFiltered?.map((object) => {
              return (
                <div className="item">
                  <p>{renderDataToString(object[columnTitle])}</p>
                </div>
              );
            })}
          </div>
        ))}
      </div>
      {enableSearchbar && (
        <p className="pt-3 pl-3">
          Mostrando {listFiltered.length} registros filtrados ( {list.length} registros totales en página)
        </p>
      )}
    </div>
  );
}

export default TableList;
