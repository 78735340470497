import { Bounce, toast } from "react-toastify";

export const ToastMessage = {
  success: "Operación exitosa",
  error: "Ocurrió un error inesperado",
};

const toastConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export const toastInfo = (message: string) => {
  //@ts-ignore
  toast.info(message, toastConfig);
};

export const toastSuccess = (message: string) => {
  //@ts-ignore
  toast.success(message || ToastMessage.success, toastConfig);
};

export const toastError = (message?: string) => {
  //@ts-ignore
  toast.error(message || ToastMessage.error, toastConfig);
};
