import React from "react";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import Button from "../Button/Button";
import ButtonOutline from "../ButtonOutline/ButtonOutline";
import "./ModalConfirmation.css";

interface Props {
  show: boolean;
  title?: string;
  message?: string;
  onClose: () => void;
  onConfirm?: () => void;
  topBorderWarn?: boolean;
}
function ModalConfirmation({
  show,
  onClose,
  onConfirm,
  message,
  title,
  topBorderWarn = true,
}: Props) {
  return (
    <SlDialog
      label={title}
      open={show}
      noHeader
      onSlAfterHide={onClose}
      className={`shoelace-modal ${
        topBorderWarn ? "warn-border" : "success-border"
      }`}
    >
      <div className="custom-modal">
        {title && (
          <strong className="custom-modal-submessage text-base title">
            {title}
          </strong>
        )}
        {message && <p className="mb-2 mt-0 dialog-text">{message}</p>}
        <div className="flex flex-row justify-around w-80">
          <ButtonOutline
            label="Cancelar"
            variant="warning"
            onClick={onClose}
            className="text-bold outline-button"
          />
          <Button
            label="Confirmar"
            onClick={onConfirm}
            theme="yellow"
            className="pl-5 pr-4 text-bold"
          />
        </div>
      </div>
    </SlDialog>
  );
}

export default ModalConfirmation;
