import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { ToastMessage, toastError } from "../../../../utils/toast";
import { AlumnosInitState } from "../../Slices/alumnosSlice";

export const getDetalleDeAlumno = createAsyncThunk(
  "dashboard/getDetalleDeAlumno",
  async (idAlumnoPrograma: number, thunkAPI) => {
    try {
      console.log("getDetalleDeAlumno...");
      const response = await dashboardAPI.obtenerDetalleAlumno(
        idAlumnoPrograma
      );
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getDetalleDeAlumnoBuilder = (
  builder: ActionReducerMapBuilder<AlumnosInitState>
) => {
  builder.addCase(getDetalleDeAlumno.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getDetalleDeAlumno.fulfilled, (state, action) => {
    console.log("getDetalleDeAlumno fulfilled :>> ", action);
    state.loading = false;
    state.detalleDeAlumno = action.payload;
  });
  builder.addCase(getDetalleDeAlumno.rejected, (state, action) => {
    console.log("getDetalleDeAlumno rejected :>> ", action);
    toastError(ToastMessage.error);
    state.loading = false;
  });
};
