import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { DashboardInitState } from "../../Slices/dashboardSlice";
import { ToastMessage, toastError } from "../../../../utils/toast";

export const getQueues = createAsyncThunk(
  "dashboard/getQueues",
  async (_, thunkAPI) => {
    try {
      console.log("getQueues...");
      const response = await dashboardAPI.obtenerDetallesDeColas();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getQueuesBuilder = (
  builder: ActionReducerMapBuilder<DashboardInitState>
) => {
  builder.addCase(getQueues.pending, (state, action) => {
    state.loadingPageScreen = true;
  });
  builder.addCase(getQueues.fulfilled, (state, action) => {
    console.log("getQueues fulfilled :>> ", action);
    state.loadingPageScreen = false;
    state.queuesData = action.payload || [];
  });
  builder.addCase(getQueues.rejected, (state, action) => {
    console.log("getQueues rejected :>> ", action);
    toastError(ToastMessage.error);
    state.loadingPageScreen = false;
  });
};
