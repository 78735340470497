import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { toastError, ToastMessage } from "../../../../utils/toast";
import { AlumnosInitState } from "../../Slices/alumnosSlice";

export const getAlumnosInicializados = createAsyncThunk(
  "dashboard/getAlumnosInicializados",
  async (_, thunkAPI) => {
    try {
      console.log("getAlumnosInicializados...");
      const response = await dashboardAPI.alumnosInicializados();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const postBorrarTodosLosAlumnos = createAsyncThunk(
  "dashboard/postBorrarTodosLosAlumnos",
  async (_, thunkAPI) => {
    try {
      console.log("postBorrarTodosLosAlumnos...");
      const response = await dashboardAPI.borrarTodosLosAlumnos();
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const postSincronizarAlumnos = createAsyncThunk<any, { i: number | null; p: number | null; o: number | null }>(
  "dashboard/postSincronizarAlumnos",
  async (params, thunkAPI) => {
    const { i, p, o } = params
    try {
      console.log("postSincronizarAlumnos..." + i + " - " + p + " - " + o);
      const response = await dashboardAPI.syncronizarAlumnos(i, p, o);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const postResetearIPO = createAsyncThunk<any, { i: number | null; p: number | null; o: number | null }>(
  "dashboard/postSincronizarAlumnos",
  async (params, thunkAPI) => {
    const { i, p, o } = params
    try {
      console.log("postSincronizarAlumnos..." + i + " - " + p + " - " + o);
      const response = await dashboardAPI.postResetearIPO(i, p, o);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const postBorrarIPO = createAsyncThunk<any, { i: number | null; p: number | null; o: number | null }>(
  "dashboard/postSincronizarAlumnos",
  async (params, thunkAPI) => {
    const { i, p, o } = params
    try {
      console.log("postSincronizarAlumnos..." + i + " - " + p + " - " + o);
      const response = await dashboardAPI.postBorrarIPO(i, p, o);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const postVencerOfertas = createAsyncThunk<any, { i: number | null; p: number | null; o: number | null }>(
  "dashboard/postSincronizarAlumnos",
  async (params, thunkAPI) => {
    const { i, p, o } = params
    try {
      console.log("postSincronizarAlumnos..." + i + " - " + p + " - " + o);
      const response = await dashboardAPI.postVencerOfertas(i, p, o);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const postInvalidarOfertas = createAsyncThunk<any, { i: number | null; p: number | null; o: number | null }>(
  "dashboard/postSincronizarAlumnos",
  async (params, thunkAPI) => {
    const { i, p, o } = params
    try {
      console.log("postSincronizarAlumnos..." + i + " - " + p + " - " + o);
      const response = await dashboardAPI.postInvalidarOfertas(i, p, o);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAlumnosInicializadosBuilder = (
  builder: ActionReducerMapBuilder<AlumnosInitState>
) => {
  builder.addCase(getAlumnosInicializados.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getAlumnosInicializados.fulfilled, (state, action) => {
    console.log("getAlumnosInicializados fulfilled :>> ", action);
    state.loading = false;
    state.alumnosInicializados = action.payload?.alumnos || [];
  });
  builder.addCase(getAlumnosInicializados.rejected, (state, action) => {
    console.log("inicializarAlumno rejected :>> ", action);
    toastError(ToastMessage.error);
    state.loading = false;
  });
};
